<template>
  <div>
    <!--begin::Dashboard-->
<!--    <v-col class="row">-->
      <v-col cols="12" sm="12">
        <b-alert
            show
            variant="light"
            class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
        >
<!--          <div class="alert-icon">-->
<!--            <span class="svg-icon svg-icon-lg">-->
<!--              <inline-svg src="media/svg/icons/Home/Home.svg"/>-->
<!--            </span>-->
<!--          </div>-->
          <div class="alert-text mt-2 text-center">
            <h1 style="color: #000000"><b>Market Insights</b></h1>
          </div>
        </b-alert>
      </v-col>
      <v-col cols="12" sm="12">
        <v-tabs v-model="activeTab" background-color="#002366" centered dark>
          <v-tab>Transacted</v-tab>
          <v-tab>Advertised</v-tab>
        </v-tabs>
        <v-tabs-items touchless v-model="activeTab">
          <v-tab-item>
            <TransactionsTable />
          </v-tab-item>
          <v-tab-item>
            <AdsTable />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
<!--    </v-col>-->
  </div>
  <!--end::Dashboard-->
</template>

<script>
// import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import TransactionsTable from "@/view/pages/realstate/market_insights/inc/TransactionsTable.vue";
import AdsTable from "@/view/pages/realstate/market_insights/inc/AdsTable.vue";

export default {
  name: "market-insights",
  components: {
    TransactionsTable,
    AdsTable
  },
  mounted() {
    this.screenResolution = screen.orientation.type
    // this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  }
};
</script>
